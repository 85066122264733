<template>
    <div class="services-cards-container">
        <ServiceCardComponent v-show="!isWalletGlobal" v-for="( menu, index ) in menus" :key="index" :icon="menu.icon" :name="menu.name"/>
        <ServiceCardComponent v-show="isWalletGlobal" v-for="( menu, index ) in globalMenus" :key="index" :icon="menu.icon" :name="menu.name"/>
    </div>
</template>
<script>
import { ref } from 'vue';

import ServiceCardComponent from '@/modules/property-details-children/components/ServiceCardComponent.vue';

export default {
  components: {
    ServiceCardComponent
  },
  props: ['isWalletGlobal'],
  setup() {
    const menus = ref([
      /* eslint-disable global-require */
      {
        icon: `${require('@/assets/icons/services-members-icon.svg')}`, name: 'Members'
      },
      {
        icon: `${require('@/assets/icons/services-projects-icon.svg')}`, name: 'Projects'
      },
      {
        icon: `${require('@/assets/icons/services-storage-icon.svg')}`, name: 'Storage'
      },
      {
        icon: `${require('@/assets/icons/services-billing-icon.svg')}`, name: 'Billing'
      },
      {
        icon: `${require('@/assets/icons/services-transaction-icon.svg')}`, name: 'Transaction'
      },
      /* eslint-enable global-require */
    ]);

    const globalMenus = ref([
      /* eslint-disable global-require */
      {
        icon: `${require('@/assets/icons/services-transaction-icon.svg')}`, name: 'Transaction'
      },
      {
        icon: `${require('@/assets/icons/services-storage-icon.svg')}`, name: 'Storage'
      },
      {
        icon: `${require('@/assets/icons/services-billing-icon.svg')}`, name: 'Billing'
      },
      /* eslint-enable global-require */
    ]);

    return {
      menus,
      globalMenus
    };
  }
};
</script>
<style lang="scss" scoped>
.services-cards-container {
    display:flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5rem;
    overflow: hidden;
    overflow-x: auto;
}
</style>
