<template>
    <el-card>
        <div class="card-content">
            <div class="column-one">
                <p class="project-name">Roof top repair</p>
                <p class="project-status"><span><img src="@/assets/icons/warning-icon.svg" alt="warning-icon"></span> Awaiting for deposit to move to the Phase 2</p>
            </div>
            <div class="column-two">
               <div class="thumbnail-container">
                  <img src="@/assets/icons/project-thumbnail-icon.svg" alt="project-thumbnail">
                  <img src="@/assets/icons/project-thumbnail-icon.svg" alt="project-thumbnail">
                  <img src="@/assets/icons/project-thumbnail-icon.svg" alt="project-thumbnail">
                  <div class="plus-count">
                      <p>+2</p>
                  </div>
               </div>
               <el-icon :size="15" color="rgba(12, 15, 74, 0.5)"><ArrowRightBold/></el-icon>
            </div>
        </div>
    </el-card>
</template>
<script>
import { ArrowRightBold } from '@element-plus/icons';

export default {
  components: {
    ArrowRightBold
  }
};
</script>
<style lang="scss" scoped>
.el-card {
    border-radius: 8px;
    cursor: pointer;
    padding:.5rem;

    .card-content {
        display:flex;
        align-items: center;
        justify-content: space-between;
    }

    .column-one {
        display:flex;
        flex-direction: column;
        gap: .5rem;

        p {
            margin: 0;
        }
        .project-name {
            font-family: Mulish;
            font-size: 18px;
            font-weight: 700;
            line-height: 15px;
            letter-spacing: 0.25px;
            text-align: left;
            color: #0C0F4A;
        }
        .project-status {
            //styleName: Caption;
            font-family: Mulish;
            font-size: 13px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.4000000059604645px;
            text-align: left;
            color: rgba(12, 15, 74, 0.5);
            display:flex;
            gap: .3rem;
            align-items:center;

            span {
                display:flex;
                align-items:center;
                justify-content: center;
            }
        }
    }

    .column-two {
        display:flex;
        align-items:center;
        justify-content: center;
        gap: 2rem;

        .thumbnail-container {
            display:flex;
            align-items:center;
            justify-content: center;
            gap: 1rem;

            .plus-count {
                width: 42px;
                height: 42px;
                display:flex;
                align-items:center;
                justify-content: center;
                border-radius: 10px;
                background: rgba(12, 15, 74, 0.05);

                p {
                    margin: 0;
                    font-family: Mulish;
                    font-size: 13px;
                    font-weight: 700;
                    line-height: 16px;
                    letter-spacing: 0.4000000059604645px;
                    text-align: center;
                    color: #0C0F4A;
                }
            }
        }

        .el-icon {
            cursor: pointer;
        }
    }
}
</style>
