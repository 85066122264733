<template>
    <div class="main-container">
        <div class="card-container">
            <p class="header">Save card</p>
            <el-card>
                <div class="column-one">
                    <img src="@/assets/icons/master-card-icon.svg">
                    <div class="card-details">
                        <div class="details">
                            <p class="name">Mastercard ending in 5678</p>
                            <p class="expiry">Expiry 06/24</p>
                        </div>
                        <p class="default">Seted as default <span><el-icon :size="7" color="#fff"><Check/></el-icon></span></p>
                    </div>
                </div>
                <div class="column-two">
                    <el-divider direction="vertical" />
                    <p class="cta" @click="changeFormState">Edit</p>
                </div>
            </el-card>
        </div>
    </div>
</template>
<script>
import { Check } from '@element-plus/icons';

export default {
  components: {
    Check,
  },
  setup(props, { emit }) {
    function changeFormState() {
      emit('open-billing-form', true);
    }
    return {
      changeFormState,
    };
  }
};
</script>
<style lang="scss" scoped>
.header {
    font-family: Mulish;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.25px;
    text-align: left;
    color: #0C0F4A;
    display:flex;
    gap: 1rem;
    align-items: center;
    margin: 0;

    span {
        display:flex;
        align-items: center;
    }
}

.pointer {
    cursor: pointer;
}
.main-container {
    .card-container {
      display:flex;
      flex-direction: column;
      gap: 1rem;

      .el-card {
        background: transparent;
        border: 2px solid rgba(12, 15, 74, 0.1);
        border-radius: 8px;
        height: 141px;

        :deep(.el-card__body) {
          display: flex;
          justify-content: space-between;
          height: 101px;
        }
        .column-one {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 1rem;

            .card-details {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height:100%;

                 .details {
                     display: flex;
                     flex-direction: column;
                     gap: .4rem;
                     .name {
                        margin: 0;
                        font-family: Mulish;
                        font-size: 14px;
                        font-weight: 700;
                        letter-spacing: 0.10000000149011612px;
                        text-align: left;
                        color: #0C0F4A;
                     }
                     .expiry {
                        margin: 0;
                        font-family: Mulish;
                        font-size: 14px;
                        font-weight: 400;
                        letter-spacing: 0.10000000149011612px;
                        text-align: left;
                        color: #0C0F4A;
                     }
                 }

                .default {
                    margin: 0;
                    font-family: Mulish;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 22px;
                    letter-spacing: 0.25px;
                    text-align: left;
                    color: rgba(12, 15, 74, 0.5);
                    display: flex;
                    align-items: center;
                    gap: .5rem;

                    span {
                      width: 13px;
                      height: 13px;
                      border-radius: 100%;
                      box-sizing: border-box;
                      padding: .2rem;
                      background: rgba(12, 15, 74, 0.5);
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    }
                }
            }
        }

        .column-two {
            display:flex;
            justify-content: center;
            align-items: center;
            gap:.3rem;
            height:20px;
            .cta {
                margin: 0;
                font-family: Mulish;
                font-size: 12px;
                font-weight: 600;
                line-height: 22px;
                letter-spacing: 0.25px;
                text-align: left;
                color: #FAA100;
                cursor: pointer;
            }
        }
      }

      .el-card.is-always-shadow {
        box-shadow: none;
      }
    }
}
</style>
