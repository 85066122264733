<template>
    <div class="add-members-transaction-container flex-column jc-sb">
        <div class="wrapper flex-column">
           <div class="row">
             <BillingMembersCountComponent/>
            </div>
            <BillingPaymentMethodComponent/>
        </div>
        <div class='wrapper flex-column'>
           <div class="flex-row ai-c jc-sb">
              <p class="label default-text">Members fee ({{getTotalMember + getAddedMember}} seats)</p>
              <p class="amount default-text">{{getAddedMember > 0 ? getTotalStorageAmount : '£0.00'}}</p>
           </div>
           <el-divider />
           <div class="flex-row ai-c jc-sb">
              <p class="label default-text">Sub total</p>
              <p class="amount default-text">{{getTotalStorageAmount}}</p>
           </div>
           <div class="col flex-row ai-c jc-sb">
              <p class="label default-text">VAT (20%)</p>
              <p class="amount default-text">{{getVat ? getVat : '£0.00'}}</p>
           </div>
           <div class="flex-row ai-c jc-sb">
              <p class="label bold default-text">Total <span>( billed monthly )</span></p>
              <p class="amount bold default-text">{{getBillMonthly ? getBillMonthly : '£0.00'}}</p>
           </div>

           <el-button type="primary" class="button-cta" round disabled v-if="getPaymentMethod === ''">PAY</el-button>
           <el-button type="primary" class="button-cta active flex-row ai-c jc-c" round v-else>
               BUY WITH
               <inline-svg :src="require(`@/assets/icons/a-payment-white.svg`)" v-if="getPaymentMethod === 'apple-pay'"></inline-svg>
               <inline-svg :src="require(`@/assets/icons/g-payment.svg`)" v-else></inline-svg>
            </el-button>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import BillingMembersCountComponent from '@/modules/properties-billing/components/BillingMembersCountComponent.vue';
import BillingPaymentMethodComponent from '@/modules/properties-billing/components/BillingPaymentMethodComponent.vue';
import { BILLING_STORE } from '@/store/modules/billing';

export default defineComponent({
  components: {
    BillingMembersCountComponent,
    BillingPaymentMethodComponent
  },
  computed: {
    ...mapGetters(BILLING_STORE, ['getTotalMember', 'getPerMemberPrice', 'getPaymentMethod', 'getAddedMember']),

    getTotalStorageAmount() {
      return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'GBP' }).format(this.totalStorageAmountFormula());
    },
    getVat() {
      if (this.getTotalStorageAmount !== '£0.00') {
        return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'GBP' }).format(this.vatFormula());
      }
      return false;
    },
    getBillMonthly() {
      if (this.getTotalStorageAmount !== '£0.00') {
        return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'GBP' }).format(this.monthlyBillingFormula());
      }
      return false;
    }
  },
  methods: {
    totalStorageAmountFormula() {
      return this.getPerMemberPrice * this.getAddedMember;
    },
    vatFormula() {
      return this.totalStorageAmountFormula() * 0.20;
    },
    monthlyBillingFormula() {
      return this.totalStorageAmountFormula() + this.vatFormula();
    }
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.default-text {
    font-family: Mulish;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.25px;
    text-align: left;
    margin: 0;
    color: #0C0F4A;
}
.flex-row {
    display: flex;
    gap: 1rem;
}
.flex-column {
    @extend .flex-row;
    flex-direction: column;
}
.ai-c {
    align-items: center;
}
.jc-sb {
    justify-content: space-between;
}
.jc-c {
    justify-content: center;
}
.el-divider {
    margin: 0;
}
.add-members-transaction-container {
  gap: 2rem;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(42, 42, 42, 0.02);
  border-radius: 8px;
  padding: 2.2rem 2rem;
  box-sizing: border-box;
  height: 535px;
}
.row {
    width: 100%;
    padding-bottom: 1rem;
    border-bottom:  2px solid rgba(12, 15, 74, 0.1);
}
.wrapper {
    gap: 1rem;
}
.label{
    span {
        font-size: 12px;
        font-weight: 600;
        color: rgba(12, 15, 74, 0.5);
    }
}
.amount {
    font-weight: 700;
}
.bold {
    font-size: 18px;
    font-weight: 700;
    padding-bottom: .2rem;
}
.button-cta {
    height: 42px;
    border-radius: 8px;
    background: rgba(12, 15, 74, 0.1);
    font-family: Mulish;
    font-size: 15px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-align: center;
    color: rgba(12, 15, 74, 0.5);
    font-family: Mulish;
    border: none;

    &:hover {
        background: rgba(12, 15, 74, 0.1);
        color: rgba(12, 15, 74, 0.5);
    }
}
.active {
    background: #000000;
    color: #FFFFFF;

    :deep(span) {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .7rem;
    }

    &:hover {
        background: #000000;
        color: #FFFFFF;
    }
}

@include media-xs-max-width() {
  .add-members-transaction-container {
    height: auto;
  }
}
</style>
