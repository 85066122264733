<template>
    <el-card>
        <div class="content">
           <p class="type">{{storage}}</p>
           <p class="price">{{price}} <span>monthly</span></p>
           <p class="vat">+ VAT</p>
        </div>
    </el-card>
</template>
<script>
import { computed } from 'vue';

export default {
  props: ['data', 'index'],
  setup(props) {
    const storage = computed(() => {
      return props.data.storage;
    });
    const price = computed(() => {
      return props.data.price;
    });

    return {
      storage,
      price,
    };
  }
};
</script>
<style lang="scss" scoped>
.el-card {
    // max-width: 248px;
    height: 128px;
    border-radius: 8px;
    border: 2px solid #FFF;
    box-sizing: border-box;
    cursor: pointer;

     :deep(.el-card__body) {
        height: 100%;
        box-sizing: border-box;
    }
}
.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    gap: .5rem;
    height: 100%;

    p {
        margin: 0;
    }

    .type {
        font-family: Mulish;
        font-size: 24px;
        font-weight: 700;
        line-height: 15px;
        letter-spacing: 0.25px;
        text-align: center;
        color: #FAA100;
    }

    .price {
        font-family: Mulish;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.15000000596046448px;
        text-align: left;
        color: #0C0F4A;

        span {
            font-family: Mulish;
            font-size: 13px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.4000000059604645px;
            text-align: left;
            color: rgba(12, 15, 74, 0.5);
        }
    }

    .vat {
        font-family: Mulish;
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.4000000059604645px;
        text-align: left;
        color: rgba(12, 15, 74, 0.5);
    }
}
</style>
