<template>
    <div class="payment-method-container flex-column">
        <div class="row flex-row ai-c">
          <el-icon class="show-in-mobile" :size="17" color="rgba(12, 15, 74, 0.3)" @click="onBackJourney"><ArrowLeftBold/></el-icon>
          <!-- <p class="default-text">Select payment method</p>
          <el-icon :size="17" color="rgba(12, 15, 74, 0.3)"><InfoFilled/></el-icon> -->
        </div>
        <!-- <div class="payment-method row grid">
            <div class="payment-type flex-row ai-c jc-c"
              :class="{'active': activeItem === item.id}"
              v-for="(item, index) in paymentData"
              :key="index"
              @click="chooseMethod(item)"
            >
             <div class="payment-image-container flex-row ai-c jc-c">
                <img :src="item.icon" alt="payment method icons">
             </div>
            </div>
        </div> -->
    </div>
</template>
<script>
import { ArrowLeftBold } from '@element-plus/icons';
import { mapActions } from 'vuex';

import { BILLING_STORE } from '@/store/modules/billing';

export default {
  components: {
    // InfoFilled,
    ArrowLeftBold
  },
  data() {
    return {
      paymentData: [
        /* eslint-disable global-require */
        {
          id: 1,
          type: 'apple-pay',
          icon: `${require('@/assets/icons/a-payment.svg')}`
        },
        {
          id: 2,
          type: 'google-pay',
          icon: `${require('@/assets/icons/g-payment.svg')}`
        },
        {
          id: 3,
          type: 'google-pay-card',
          icon: `${require('@/assets/icons/c-payment.svg')}`
        }
        /* eslint-disable global-require */
      ],
      activeItem: null
    };
  },
  emits: ['go-to-payment-plan'],
  methods: {
    ...mapActions(BILLING_STORE, ['setPaymentMethod']),

    chooseMethod(method) {
      this.activeItem = method.id;
      this.setPaymentMethod(method.type);
    },
    onBackJourney() {
      this.$emit('go-to-payment-plan');
    }
  },
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.default-text {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.25px;
    text-align: left;
    margin: 0;
    color: #0C0F4A;
}
.flex-row {
    display: flex;
    gap: 1rem;
}
.flex-column {
    @extend .flex-row;
    flex-direction: column;
}
.ai-c {
    align-items: center;
}
.jc-sb {
    justify-content: space-between;
}
.jc-c {
    justify-content: center;
}
.payment-method-container {
}
.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1.4rem;
}
.row {}
.payment-type {
    border-radius: 8px;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(42, 42, 42, 0.1);
    height: 48px;
    box-sizing: border-box;
    cursor: pointer;
    border: 2px solid transparent;
}
.payment-image-container {
  width: 80%;
  height: 70%;
  box-sizing: border-box;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}
.active {
    border: 2px solid #FFA500;
    transition: all .3s ease-in-out;
}
@include media-xs-max-width() {
  .grid {
    gap: .8rem;
  }
  .payment-method {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
</style>
