<template>
    <div class="title">
        <el-icon @click="onBack">
            <ArrowLeftBold />
        </el-icon>
        <span>Please input your card details</span>
    </div>
    <el-form class="form">
        <el-form-item label="Card holder name">
            <el-input v-model="data.cardHolderName"/>
        </el-form-item>
        <el-form-item label="Card number">
            <el-input v-model="data.cardNumber"/>
        </el-form-item>
        <el-form-item label="Expiry date">
            <el-input v-model="data.expiryDate"/>
        </el-form-item>
        <el-form-item label="CVC">
            <el-input v-model="data.cvc"/>
        </el-form-item>
        <el-button :disabled="!checkInputFilled" :class="[checkInputFilled ? 'active' : 'disabled']" @click="submit">Save & Next</el-button>
    </el-form>
</template>

<script>
import { ArrowLeftBold } from '@element-plus/icons';

export default {
  components: {
    ArrowLeftBold
  },
  props: ['onBack', 'cardDetails', 'checkInputFilled', 'submit'],
  data() {
    return {
      data: this.cardDetails
    };
  },
  methods: { }
};
</script>

<style lang="scss" scoped>
.title {
    display: flex;
    align-items: center;
    gap: 12px;
    color: #0C0F4A;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: .25px;
    // margin-top: 50px;
    margin-bottom: 30px;

    .el-icon {
        cursor: pointer;
    }
}

.form {
    .base-button {
        margin-top: 50px;
        width: 100%;
        text-transform: uppercase;
        border-radius: 8px;
        border: 0;
    }
    .disabled {
        @extend .base-button;
        background-color: rgba(12, 15, 74, 0.05);
        color: rgba(12, 15, 74, 0.5);
    }
    .active {
        @extend .base-button;
        background-color: #FAA100;
        color: #fff;
    }
    :deep(.el-input__inner) {
        border: 0;
        border-bottom: 2px solid rgba(12, 15, 74, 0.2);
    }

    :deep(.el-form-item__label) {
        display: flex;
        align-items: center;
        flex-basis: 20%;
        text-align: left;
        color: #0C0F4A;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: .25px;
    }
}
</style>
