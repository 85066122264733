<template>
    <div class="property-wallet-container">
      <!-- MOBILE SCREENS -->
      <MobileMoneyTransactionComponent/>

      <FlexColumnWithHeaderSlot header="Services">
          <ServicesCardsContainer/>
      </FlexColumnWithHeaderSlot>
      <FlexColumnWithHeaderSlot :header="servicesOnMenu" :hide="servicesOnMenu === 'Transaction' && true">
          <MembersPaymentBoxComponent v-if="servicesOnMenu === 'Members'"/>
          <ProjectsPaymentBoxComponent v-if="servicesOnMenu === 'Projects'"/>
          <StoragePaymentBoxComponent v-if="servicesOnMenu === 'Storage'"/>
          <BillingSettingsComponent v-if="servicesOnMenu === 'Billing'"/>
          <FlexColumnWithHeaderSlot :header="servicesOnMenu" v-if="servicesOnMenu === 'Transaction'">
            <TransactionCardComponent/>
          </FlexColumnWithHeaderSlot>
          <EmptyStateComponent v-if="servicesOnMenu === ''"/>
      </FlexColumnWithHeaderSlot>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

import MobileMoneyTransactionComponent from '@/modules/e-wallet/components/MobileMoneyTransactionComponent.vue';
import TransactionCardComponent from '@/modules/e-wallet/components/TransactionCardComponent.vue';
import BillingSettingsComponent from '@/modules/property-details-children/components/BillingSettingsComponent.vue';
import EmptyStateComponent from '@/modules/property-details-children/components/EmptyStateComponent.vue';
import FlexColumnWithHeaderSlot from '@/modules/property-details-children/components/FlexColumnWithHeaderSlot.vue';
import MembersPaymentBoxComponent from '@/modules/property-details-children/components/MembersPaymentBoxComponent.vue';
import ProjectsPaymentBoxComponent from '@/modules/property-details-children/components/ProjectsPaymentBoxComponent.vue';
import ServicesCardsContainer from '@/modules/property-details-children/components/ServicesCardsContainer.vue';
import StoragePaymentBoxComponent from '@/modules/property-details-children/components/StoragePaymentBoxComponent.vue';
import { PROPERTY_STORE } from '@/store/modules/property-details';

export default {
  components: {
    MembersPaymentBoxComponent,
    FlexColumnWithHeaderSlot,
    ServicesCardsContainer,
    EmptyStateComponent,
    ProjectsPaymentBoxComponent,
    StoragePaymentBoxComponent,
    BillingSettingsComponent,
    TransactionCardComponent,
    MobileMoneyTransactionComponent
  },
  computed: {
    ...mapGetters(PROPERTY_STORE, ['servicesOnMenu']),
  },
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.property-wallet-container {
    width:100%;
    height:100%;
    padding-bottom: 5rem;
    display:flex;
    flex-direction: column;
    gap: 3rem;
    max-width:1030px;
    // background:yellow;
}
@include media-md-max-width() {
  .property-wallet-container {
    gap: 1.5rem;
  }
}
</style>
