<template>
    <div class="members-payment-container flex-column">
        <MembersPlanDetailsComponent/>
        <AddMembersTransactionComponent/>

        <!-- MODALS -->
        <CurrentMembersPlanModal :show="openCurrentMembersPlanModal" @close="setOpenCurrentMembersPlanModal(false)"/>
        <DeleteMemberConfirmationModal :show="openConfirmDeleteMembersPlanModal" @close="setOpenConfirmDeleteMembersPlanModal(false)"/>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import AddMembersTransactionComponent from '@/modules/property-details-children/components/AddMembersTransactionComponent.vue';
import CurrentMembersPlanModal from '@/modules/property-details-children/components/CurrentMembersPlanModal.vue';
import DeleteMemberConfirmationModal from '@/modules/property-details-children/components/DeleteMemberConfirmationModal.vue';
import MembersPlanDetailsComponent from '@/modules/property-details-children/components/MembersPlanDetailsComponent.vue';
import { WALLET_STORE } from '@/store/modules/wallet';

export default defineComponent({
  components: {
    MembersPlanDetailsComponent,
    AddMembersTransactionComponent,
    CurrentMembersPlanModal,
    DeleteMemberConfirmationModal
  },
  computed: {
    ...mapGetters(WALLET_STORE, ['openCurrentMembersPlanModal', 'openConfirmDeleteMembersPlanModal']),
  },
  methods: {
    ...mapActions(WALLET_STORE, ['setOpenCurrentMembersPlanModal', 'setOpenConfirmDeleteMembersPlanModal']),
  }
});
</script>
<style lang="scss" scoped>
.flex-row {
    display: flex;
}
.flex-column {
    @extend .flex-row;
    flex-direction: column;
}
.members-payment-container {
    gap: 2rem;
}
</style>
