<template>
    <div class="storage-container">
        <div class="member-plan-container">
            <div class="plan-status-container">
                <p class="status-label">Current plan - <span>100GB</span></p>
                <p class="status-price">£2.49 <span>monthly</span></p>
            </div>
            <div class="progress-container">
                <el-progress type="circle" :percentage="50" :stroke-width="10">
                    <template #default="{ percentage }">
                        <span class="percentage-value"><span>{{ percentage }}</span>GB</span>
                        <span class="percentage-label">Available</span>
                    </template>
                </el-progress>
            </div>
        </div>

        <el-card class="page">
            <div class="page__payment-menu">
                <div class="page__payment" v-if="!openMethod">
                    <div class="page__payment__title">
                        Add storage
                    </div>
                    <div class="page__payment__identity">
                        <div class="page__payment__identity__card">
                            <img v-if="cardDetails.cardNumber" src="@/assets/images/mastercard.png" alt="master-card" />
                            <div>{{ cardDetails.cardNumber?.slice(-4) }}</div>
                        </div>
                        <div class="page__payment__identity__action" @click="() => (openMethod = true)">
                            Edit
                        </div>
                    </div>
                </div>
            </div>
            <div class="page__plan__type" v-if="!openMethod">
               <PlanCardComponent v-for="(item, index) in planDetails" :key="index" :data="item" class="plan-card" :class="{ 'is-active': activeIndex === index }" @click="changeActiveState(index)"/>
            </div>

            <PaymentMethodComponent v-if="openMethod" :cardDetails="cardDetails" :onBack="setOpenMethod" :checkInputFilled="checkInputFilled" :submit="cardSubmit" />

            <div class="page__bottom-items">
                <div class="page__price">
                    <div class="page__price__sub-total">
                        <div class="page__price__sub-total__title">
                            Sub total
                        </div>
                        <div class="page__price__sub-total__value">
                            £0.00
                        </div>
                    </div>
                    <div class="page__price__vat">
                        <div class="page__price__vat__title">
                            VAT (20%)
                        </div>
                        <div class="page__price__vat__value">
                            £0.00
                        </div>
                    </div>
                    <div class="page__price__card-processing">
                        <div class="page__price__card-processing__title">
                            Card processing (2%)
                        </div>
                        <div class="page__price__card-processing__value">
                            £0.00
                        </div>
                    </div>
                    <div class="page__price__total">
                        <div class="page__price__total__title">
                            Total
                        </div>
                        <div class="page__price__total__value">
                            £0.00
                        </div>
                    </div>
                </div>
                <div class="page__action">
                    <el-button class="page__action__confirm" @click="confirmBilling">PAY</el-button>
                </div>
            </div>
        </el-card>
    </div>
</template>
<script>
import PaymentMethodComponent from '@/modules/properties-billing/components/PaymentMethodComponent.vue';
import PlanCardComponent from '@/modules/property-details-children/components/PlanCardComponent.vue';

export default {
  components: {
    PaymentMethodComponent,
    PlanCardComponent
  },
  created() {
    if (!this.checkInputFilled) {
      this.openMethod = true;
      // this.openAddress = true;
    }
  },
  data() {
    return {
      cardDetails: {
        cardHolderName: '',
        cardNumber: '',
        expiryDate: '',
        cvc: ''
      },
      planDetails: [
        {
          storage: '500GB',
          price: '£3.49'
        },
        {
          storage: '1TB',
          price: '£5.49'
        },
        {
          storage: '2TB',
          price: '£7.49'
        },
      ],
      openMethod: false,
      property: null,
      activeIndex: 0,
    };
  },
  methods: {
    setOpenMethod() {
      this.openMethod = !this.openMethod;
    },
    cardSubmit() {
      this.setOpenMethod();
    },
    confirmBilling() {
      try {
        const { id } = this.$route.params;
        this.$router.push({ name: 'property-details/home', params: { id } });
      } catch (e) {
        console.log(e);
      }
    },
    changeActiveState(id) {
      this.activeIndex = id;
    }
  },
  computed: {
    checkInputFilled() {
      return Object.values(this.cardDetails).filter((item) => item).length === 4;
    },
  }
};
</script>
<style lang="scss" scoped>
.storage-container {
    display:flex;
    flex-direction: column;
    gap: 2rem;
}
.member-plan-container {
    border-radius: 8px;
    border: 1px solid rgba(250, 161, 0, 1);
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    background: #fff;

    .plan-status-container {
        display:flex;
        flex-direction: column;
        gap: 1.5rem;

        .status-label {
            font-size: 18px;
            font-weight: 400;
            line-height: 15px;
            letter-spacing: 0.25px;
            text-align: left;
            color : #0C0F4A;
            margin: 0;

            span {
                font-size: 18px;
                font-weight: 700;
                line-height: 15px;
                letter-spacing: 0.25px;
                text-align: left;
                color: #FAA100;
            }
        }
        .status-price {
            font-size: 21px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.15000000596046448px;
            text-align: left;
            margin: 0;

            span {
                font-size: 13px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0.4000000059604645px;
                text-align: left;
                color: rgba(12, 15, 74, 0.5);
            }
        }
    }

    .progress-container {
        .el-progress {
            :deep(.el-progress-circle) {
                width: 80px !important;
                height: 80px !important;
            }
        }
        .percentage-value {
            display: block;
            font-size: 12px;
            font-weight: 800;
            line-height: 25px;
            letter-spacing: 0.25px;
            text-align: center;
            color: #4F55F0;

            span {
                font-size: 20px;
                font-weight: 800;
                line-height: 25px;
                letter-spacing: 0.25px;
                text-align: center;
            }
        }
        .percentage-label {
            display: block;
            font-size: 10px;
            font-weight: 400;
            // line-height: 25px;
            letter-spacing: 0.25px;
            text-align: center;
            color:rgba(12, 15, 74, 0.5);
        }
    }
}

.text-base {
  color: #0c0f4a;
  letter-spacing: 0.25px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
}

.divider {
  border-bottom: 2px solid rgba(42, 42, 42, 0.1);
  margin: 31px 0;
}

.page {
  font-family: 'Mullish', Arial;
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  height: calc( 100% - 100px ) !important;

  :deep(.el-card__body) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
  }

  :deep(.el-scrollbar__bar.is-vertical) {
    width: 6px !important;
    display: block !important;
  }

  &__bottom-items {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
  }

  &__payment {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      @extend .text-base;
      display: flex;
      align-items: center;
    }

    &__identity {
      display: flex;
      align-items: center;
      gap: 12px;

      &__card {
        display: flex;
        gap: 10px;
        color: #0c0f4a;
        font-size: 12px;

        &:after {
          content: '';
          border-right: 2px solid rgba(206, 0, 0, 0.1);
        }
      }

      &__action {
        color: #faa100;
        font-weight: 700;
        font-weight: 12px;
        letter-spacing: 0.25px;
        cursor: pointer;
      }
    }
  }

  &__plan__type {
    display: grid;
    grid-template-columns : repeat(auto-fill, minmax(230px, 1fr));
    width: 100%;
    justify-content: center;
    gap: 2rem;

    .plan-card.is-active {
        border-color: #FAA100;
    }
  }

  &__price {
    display: flex;
    flex-direction: column;
    gap: 10px;
    line-height: 22px;
    letter-spacing: 0.25px;
    font-size: 12px;

    &__sub-total,
    &__vat,
    &__total,
    &__card-processing {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__value {
        font-weight: 700;
      }
    }

    &__total {
      font-size: 18px;
      font-weight: 700;
    }
  }

  &__action {
    display: flex;
    margin-top: 1rem;
    &__confirm {
      flex-basis: 100%;
      text-transform: uppercase;
      background-color: #faa100;
      color: #fff;
      border: 0;
      border-radius: 8px;
    }
  }
}
</style>
