<template>
    <div class="empty-state-page">
        <img class="image" src="@/assets/images/empty-state-wallet-image.svg">
        <p>Select option from above to<br> get started... </p>
    </div>
</template>
<script>
export default {
};
</script>
<style lang="scss" scoped>
.empty-state-page {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 420px);

  .image {
    width: 199px;
    height: 199px;
    object-fit: contain;
  }

  p {
    font-family: Mulish;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.4000000059604645px;
    text-align: center;
    color: rgba(12, 15, 74, 0.5);
    margin: 0;
  }
}
</style>
