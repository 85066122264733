import { defineComponent } from '@vue/runtime-core';
import { mapActions, mapGetters } from 'vuex';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import UserThumbnail from '@/core/components/ui/UserThumbnail.vue';
import { WALLET_STORE } from '@/store/modules/wallet';
export default defineComponent({
    name: 'current-members-plan-modal',
    emits: ['close'],
    components: {
        BaseModal,
        UserThumbnail
    },
    data() {
        return {
            checkedInitialMembers: [],
            initialMembersData: [
                {
                    id: 1,
                },
                {
                    id: 2,
                },
                {
                    id: 3,
                }
            ],
            hasSelected: false,
        };
    },
    props: {
        show: Boolean,
    },
    computed: {
        ...mapGetters(WALLET_STORE, ['openCurrentMembersPlanModal']),
    },
    methods: {
        ...mapActions(WALLET_STORE, ['setOpenCurrentMembersPlanModal', 'setOpenConfirmDeleteMembersPlanModal']),
        cancel() {
            this.$emit('close');
        },
        confirm() {
            this.$emit('close');
        },
        checkSelectedMembers() {
            if (this.checkedInitialMembers.length > 0) {
                this.hasSelected = true;
            }
            else {
                this.hasSelected = false;
            }
        },
        remove() {
            this.setOpenCurrentMembersPlanModal(false);
            this.setOpenConfirmDeleteMembersPlanModal(true);
        }
    }
});
