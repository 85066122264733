<template>
    <el-card @click="setServicesOnMenu(name)" :class="{'active': servicesOnMenu === name}">
        <div class="card-container">
            <inline-svg :src="icon"></inline-svg>
            <p>{{name}}</p>
        </div>
    </el-card>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import { PROPERTY_STORE } from '@/store/modules/property-details';

export default {
  props: ['icon', 'name'],
  computed: {
    ...mapGetters(PROPERTY_STORE, ['servicesOnMenu']),
  },
  methods: {
    ...mapActions(PROPERTY_STORE, ['setServicesOnMenu']),
  }
};
</script>
<style lang="scss" scoped>
.el-card {
    height: 100px;
    width: 100px;
    border-radius: 8px;
    border: 2px solid #FFF;
    cursor: pointer;
    min-width: 100px;

    :deep(.el-card__body) {
        width: 100%;
        height: 100%;
        padding: 0;
    }

    .card-container {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        align-items: center;

        p {
            margin: 0;
            font-family: Mulish;
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: 0.4000000059604645px;
            text-align: center;
            color: #0C0F4A;
        }
    }
}
.el-card.active {
    border-color: rgba(250, 161, 0, 1);
}
</style>
