<template>
    <div class="main-container">
        <div class="card-container">
            <p class="header">Billing address</p>
            <el-card>
                <div class="column-one">
                    <p class="address"><span><el-icon :size="20" color="#0C0F4A"><HomeFilled/></el-icon></span> 33 Collier Close, Epsom, KT19 9JG</p>
                </div>
                <div class="column-two">
                    <el-divider direction="vertical" />
                    <p class="cta" @click="openForm">Edit</p>
                </div>
            </el-card>
        </div>
    </div>
</template>
<script>
import { HomeFilled } from '@element-plus/icons';

export default {
  components: {
    HomeFilled
  },
  setup(props, { emit }) {
    function openForm() {
      emit('open-billing-address-form', true);
    }

    return {
      openForm,
    };
  }
};
</script>
<style lang="scss" scoped>
.header {
    font-family: Mulish;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.25px;
    text-align: left;
    color: #0C0F4A;
    display:flex;
    gap: 1rem;
    align-items: center;
    margin: 0;

    span {
        display:flex;
        align-items: center;
    }
}

.pointer {
    cursor: pointer;
}

.main-container {
    .card-container {
      display:flex;
      flex-direction: column;
      gap: 1rem;

      .el-card {
        background: transparent;
        border: 2px solid rgba(12, 15, 74, 0.1);
        border-radius: 8px;

        :deep(.el-card__body) {
          display: flex;
          justify-content: space-between;
        }
        .column-one {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;

            .address {
                font-family: Mulish;
                font-size: 14px;
                font-weight: 700;
                letter-spacing: 0.10000000149011612px;
                text-align: left;
                color: #0C0F4A;
                display:flex;
                align-items: center;
                gap: 1rem;

                span {
                    display: flex;
                    align-items: center;
                }
            }
        }

        .column-two {
            display:flex;
            justify-content: center;
            align-items: center;
            gap:.3rem;
            .cta {
                margin: 0;
                font-family: Mulish;
                font-size: 12px;
                font-weight: 600;
                line-height: 22px;
                letter-spacing: 0.25px;
                text-align: left;
                color: #FAA100;
                cursor: pointer;
            }
        }
      }

      .el-card.is-always-shadow {
        box-shadow: none;
      }
    }
}
</style>
