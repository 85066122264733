<template>
    <el-card class="page">
        <div class="page__row">
            <p class="page__header">Important</p>
            <ProjectCardComponent/>
        </div>
        <div class="page__row fixed-height">
            <p class="page__header">All</p>
            <el-scrollbar>
                <div class="content">
                  <ProjectCardComponent v-for="(item, index) in cardCount" :key="index"/>
                </div>
            </el-scrollbar>
        </div>
    </el-card>
</template>
<script>
import { ref } from 'vue';

import ProjectCardComponent from '@/modules/property-details-children/components/ProjectCardComponent.vue';

export default {
  components: {
    ProjectCardComponent
  },
  setup() {
    const cardCount = ref(7);

    return {
      cardCount
    };
  }
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.page {
    font-family: 'Mullish', Arial;
    border-radius: 8px;
    height: 621px;

    :deep(.el-card__body) {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    &__row {
        display:flex;
        flex-direction: column;
        gap: 1rem;
        height: 100%;

        .el-scrollbar {
            padding: 0;
            // height: calc(100% - 200px);
            .content {
                display:flex;
                flex-direction: column;
                gap:1rem;
                padding-bottom: 1rem;
            }
        }
    }

    &__row.fixed-height {
        height: 425px;
    }

    &__header {
        margin: 0;
        font-family: Mulish;
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0.25px;
        text-align: left;
        color: #0C0F4A;
    }
}
@include media-xs-max-width() {
    .page {
        height: auto;
    }
}
</style>
