<template>
    <div class="billing-members-count-container flex-row jc-sb ai-c">
       <div class="row-details flex-column" :class="{'ai-c': center}">
          <div class="label-container flex-row ai-c">
            <p class="label default-text">Add members</p>
            <el-icon :size="17" color="rgba(12, 15, 74, 0.3)"><InfoFilled/></el-icon>
          </div>
          <p class="payment-info default-text">£2.49 + VAT per member/ monthly</p>
       </div>
       <div class="counter row flex-row ai-c">
          <div class="action flex-row ai-c jc-c" @click="handleTotalMember('inc')">
            <inline-svg :src="require(`@/assets/icons/increment.svg`)"></inline-svg>
          </div>
            <el-input v-model.number="getMembers"></el-input>
          <div class="action flex-row ai-c jc-c" @click="handleTotalMember('desc')" :class="{'disable-button': getMembers === 0}">
            <inline-svg :src="require(`@/assets/icons/decrement.svg`)"></inline-svg>
          </div>
       </div>
    </div>
</template>
<script>
import { InfoFilled } from '@element-plus/icons';
import { mapActions, mapGetters } from 'vuex';

import { BILLING_STORE } from '@/store/modules/billing';

export default {
  components: {
    InfoFilled
  },

  props: ['center'],

  watch: {
    getAddedMember: {
      handler() {
        if (this.getAddedMember === 0 || this.getAddedMember === null || this.getAddedMember === '') {
          this.setAddedMember(0);
        }
      }
    },
  },
  computed: {
    ...mapGetters(BILLING_STORE, ['getAddedMember']),

    getMembers: {
      get() {
        return this.getAddedMember;
      },
      set(newVal) {
        if (newVal > 0) {
          this.setAddedMember(newVal);
        }
      }
    }
  },
  created() {
    // set default as 1
    this.setAddedMember(this.getAddedMember);
  },
  methods: {
    ...mapActions(BILLING_STORE, ['setAddedMember']),

    handleTotalMember(type) {
      if (type === 'inc') {
        this.setAddedMember(this.getAddedMember += 1);
      } else {
        if (this.getAddedMember < 1) return;
        this.setAddedMember(this.getAddedMember -= 1);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.default-text {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: left;
    margin: 0;
    color: #0C0F4A;
}
.flex-row {
    display: flex;
    gap: 1rem;
}
.flex-column {
    @extend .flex-row;
    flex-direction: column;
}
.ai-c {
    align-items: center;
}
.jc-sb {
    justify-content: space-between;
}
.jc-c {
    justify-content: center;
}
.billing-members-count-container {
}
.payment-info {
    font-size: 12px;
    color: #FFA500;
}
.row-details {
    gap: 0;
}
.row {
    gap: 2rem;
}
.counter {
    gap: .7rem;
}
.action {
    width: 32px;
    height: 32px;
    background-color: rgba(12, 15, 74, 0.05);
    box-sizing: border-box;
    border-radius: 100%;
    cursor: pointer;
}
.action.disable-button {
  cursor: no-drop;
}
.total {
    @extend .default-text;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: center;
}
.el-input {
    width: 95px;

    :deep(.el-input__inner) {
        background: transparent;
        border: none;
        outline: none;
        width: 100%;
        text-align: center;
        @extend .total;
    }
}

@include media-xs-max-width() {
  .billing-members-count-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .label-container {
    justify-content: center;
  }
  .label {
    text-align: center;
  }
}
</style>
