<template>
    <el-card>
        <div class="content">
           <BillingCardComponent v-if="!openMethod && !openAddress" @open-billing-form="() => (openMethod = true)"/>
           <BillingAddressCardComponent v-if="!openMethod && !openAddress" @open-billing-address-form="setOpenAddress"/>

           <PaymentMethodComponent v-if="openMethod" :cardDetails="cardDetails" :onBack="setOpenMethod" :checkInputFilled="checkInputFilled" :submit="cardSubmit" />
           <BillingAddressComponent :onBack="setOpenAddress" v-if="openAddress" @set-same-address="setSameAddress" :addressDetails="addressDetails" :submit="addressSubmit" />
        </div>
    </el-card>
</template>
<script>
import { mapActions } from 'vuex';

import BillingAddressCardComponent from '@/modules/account-settings/components/BillingAddressCardComponent.vue';
import BillingCardComponent from '@/modules/account-settings/components/BillingCardComponent.vue';
import BillingAddressComponent from '@/modules/properties-billing/components/BillingAddressComponent.vue';
import PaymentMethodComponent from '@/modules/properties-billing/components/PaymentMethodComponent.vue';
import { PROPERTY_STORE } from '@/store/modules/property-details';

export default {
  components: {
    BillingAddressCardComponent,
    BillingCardComponent,
    BillingAddressComponent,
    PaymentMethodComponent
  },
  created() {
    if (!this.checkInputFilled) {
      this.openMethod = true;
      // this.openAddress = true;
    }
  },
  data() {
    return {
      cardDetails: {
        cardHolderName: '',
        cardNumber: '',
        expiryDate: '',
        cvc: ''
      },
      addressDetails: {
        sameAddress: false,
        addressLine: '',
        city: '',
        postcode: ''
      },
      openMethod: false,
      openAddress: false,
      property: null
    };
  },
  watch: {
    'addressDetails.sameAddress': async function sameAddress(value) {
      if (!value) return;

      const { id } = this.$route.params;
      const raw = await this.getProperty(id);
      const result = { ...raw, addressData: JSON.parse(raw.addressData), epcData: JSON.parse(raw.epcData) };
      this.property = result;
      this.addressDetails.addressLine = result.epcData.address;
      this.addressDetails.city = result.addressData.POST_TOWN;
      this.addressDetails.postcode = result.addressData.POSTCODE;
    }
  },
  methods: {
    ...mapActions(PROPERTY_STORE, ['getProperty']),
    setOpenMethod() {
      this.openMethod = !this.openMethod;
    },
    setOpenAddress() {
      this.openAddress = !this.openAddress;
    },
    async setSameAddress() {
      this.addressDetails.sameAddress = !this.addressDetails.sameAddress;

      if (!this.addressDetails.sameAddress) return;

      const { id } = this.$route.params;
      const raw = await this.getProperty(id);
      const result = { ...raw, addressData: JSON.parse(raw.addressData), epcData: JSON.parse(raw.epcData) };
      this.property = result;
      this.addressDetails.addressLine = result.epcData.address;
      this.addressDetails.city = result.addressData.POST_TOWN;
      this.addressDetails.postcode = result.addressData.POSTCODE;
    },
    cardSubmit() {
      this.setOpenMethod();
      this.setOpenAddress();
    },
    addressSubmit() {
      this.setOpenAddress();
    },
    confirmBilling() {
      try {
        const { id } = this.$route.params;
        this.$router.push({ name: 'property-details/home', params: { id } });
      } catch (e) {
        console.log(e);
      }
    }
  },
  computed: {
    checkInputFilled() {
      return Object.values(this.cardDetails).filter((item) => item).length === 4;
    },
    billingAddress() {
      return `${this.addressDetails.addressLine} ${this.addressDetails.city} ${this.addressDetails.postcode && ','} ${this.addressDetails.postcode}`;
    }
  }
};
</script>
<style lang="scss" scoped>
.el-card {
    height: 621px;
    border-radius: 8px;
}
.content {
  display:flex;
  flex-direction: column;
  gap: 2rem;
}
</style>
