<template>
    <div class="members-plan-details-container flex-row ai-c jc-sb" @click="setOpenCurrentMembersPlanModal(true)">
        <div class="left flex-column">
            <p class="label default-text">Current plan - <span>1 member</span></p>
            <p class="amount default-text">£0.00 <span>monthly</span></p>
        </div>
        <div class="right flex-row ai-c">
            <UserThumbnail
            borderRadius="50px"
            width="58px"
            height="58px"
            :key="require(`@/assets/images/box-empty.jpeg`)"
            :source="require(`@/assets/images/box-empty.jpeg`)"
            >
            </UserThumbnail>
            <div class="arrow flex-row ai-c jc-c">
                <el-icon :size="15" color="#FFF"><ArrowRightBold/></el-icon>
            </div>
        </div>
    </div>
</template>
<script>
import { ArrowRightBold } from '@element-plus/icons';
import { mapActions } from 'vuex';

import UserThumbnail from '@/core/components/ui/UserThumbnail.vue';
import { WALLET_STORE } from '@/store/modules/wallet';

export default {
  components: {
    UserThumbnail,
    ArrowRightBold
  },
  methods: {
    ...mapActions(WALLET_STORE, ['setOpenCurrentMembersPlanModal']),
  }
};
</script>
<style lang="scss" scoped>
.default-text {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 700;
    line-height: 0;
    letter-spacing: 0.25px;
    text-align: left;
    margin: 0;
    color: #0C0F4A;
}
.flex-row {
    display: flex;
}
.flex-column {
    @extend .flex-row;
    flex-direction: column;
}
.ai-c {
    align-items: center;
}
.jc-sb {
    justify-content: space-between;
}
.jc-c {
    justify-content: center;
}
.members-plan-details-container {
    height: 118px;
    box-sizing: border-box;
    padding: 2.5rem 2rem;
    border-radius: 8px;
    border: 2px solid transparent;
    box-shadow: 0px 4px 20px rgba(42, 42, 42, 0.05);
    background: #FFFFFF;
    cursor: pointer;

    &:hover {
        border-color:#FFA500;
        transition: all .3s ease-in-out;
    }
}
.label {
    font-size: 18px;
    font-weight: 400;
    span {
        font-weight: 700;
        color: #FFA500;
    }
}
.amount {
    font-size: 20px;
    letter-spacing: 0.15000000596046448px;

    span {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.4000000059604645px;
        color: rgba(12, 15, 74, 0.5);
    }
}
.left{
  gap: 2rem;
}
.right{
    gap: 3rem;
}
.arrow {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background: #FFA500;
}
</style>
