<template>
  <div class="header">
    <div class="header__title">
      <el-icon @click="onBack">
        <ArrowLeftBold />
      </el-icon>
      <span>Please input your billing address</span>
    </div>
    <el-checkbox class="same-address" v-model="data.sameAddress">Same as the address that I'm listing</el-checkbox>
  </div>
  <el-form class="form">
    <el-input v-model="searchPostcode" placeholder="Postcode" v-if="!data.sameAddress" />
    <div v-if="postcodes.length > 0" class="list-container">
      <ul class="list">
        <li v-for="{ DPA } in postcodes" :key="DPA.UPRN" @click="selectAddress(DPA)">
          <div>{{ DPA.ADDRESS }}</div>
        </li>
      </ul>
    </div>
    <div v-if="data.sameAddress">
      <el-input type="text" placeholder="Address Line" v-model="data.addressLine" />
      <el-input type="text" placeholder="City" v-model="data.city" />
      <el-input type="text" placeholder="Postcode" v-model="data.postcode" />
    </div>
    <div class="form__manual">
      Enter address manually
    </div>
    <el-button class="action" @click="submit">Save & Next</el-button>
  </el-form>
</template>

<script>
import { ArrowLeftBold } from '@element-plus/icons';
import axios from 'axios';
import { mapActions } from 'vuex';

import { VUE_APP_OS_API, VUE_APP_OS_POSTCODE_URL } from '@/config';
import { PROPERTY_STORE } from '@/store/modules/property-details';

export default {
  components: {
    ArrowLeftBold
  },
  props: ['onBack', 'addressDetails', 'submit'],
  data() {
    return {
      sameAddress: false,
      data: this.addressDetails,
      sameAddressForm: {
        addressLine: '33 Collier Close',
        city: 'Epsom',
        postcode: 'KT19 9JG'
      },

      searchPostcode: '',
      postcodes: [],
      step: 1,
      selectedAddress: null
    };
  },
  watch: {
    searchPostcode(value) {
      this.postcodes = [];
      this.searchPostcode = value
        .split(' ')
        .join('')
        .toUpperCase();
      if (value.length < 5) return;
      this.findAddress();
    }
  },
  methods: {
    ...mapActions(PROPERTY_STORE, ['setProperty', 'createProperties']),
    async findAddress() {
      this.postcodes = [];
      try {
        const response = await axios.get(`${VUE_APP_OS_POSTCODE_URL}?postcode=${this.searchPostcode}&output_srs=EPSG:4326&key=${VUE_APP_OS_API}`);
        this.postcodes = response.data.results;
      } catch (e) {
        console.log(e);
      }
    },
    async createAndRouteToPropertyDetails() {
      try {
        const { id } = this.$route.params;
        this.$router.push({ name: 'property-details/home', params: { id } });
      } catch (e) {
        console.log(e);
      }
    },
    async selectAddress(address) {
      console.log(address);
      this.selectedAddress = null;
      this.selectedAddress = {
        ...address,
        latlng: [address.LAT, address.LNG]
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  align-items: center;

  &__title {
    display: flex;
    align-items: center;
    gap: 12px;
    color: #0c0f4a;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.25px;

    .el-icon {
      cursor: pointer;
    }
  }

  :deep(.el-radio__inner) {
    padding: 8px;

    &:after {
      background-color: #ffa500;
      padding: 2px;
    }
  }

  :deep(.el-radio__label) {
    color: #0c0f4a;
  }
}

.form {
  &__manual {
    color: rgba(12, 15, 74, 0.5);
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    margin-top: 20px;
  }

  .action {
    margin-top: 50px;
    width: 100%;
    text-transform: uppercase;
    border: 0;
    background-color: rgba(12, 15, 74, 0.05);
    color: rgba(12, 15, 74, 0.5);
  }

  :deep(.el-input__inner) {
    border: 0;
    border-bottom: 2px solid rgba(12, 15, 74, 0.2);
    padding: 0;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 22px;
    margin-top: 25px;
  }

  :deep(.el-form-item__label) {
    display: flex;
    align-items: center;
    flex-basis: 20%;
    text-align: left;
    color: #0c0f4a;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.25px;
  }
}

.same-address {
  :deep(.el-checkbox__label) {
    line-height: 22px;
    white-space: pre-line;
    color: #0c0f4a;
  }
  :deep(.el-checkbox__input .el-checkbox__inner) {
    border-radius: 100px;
    border: 1px solid rgba(12, 15, 74, 0.5);
    width: 18px;
    height: 18px;
  }
  :deep(.el-checkbox__input.is-checked .el-checkbox__inner::after) {
    display: none;
  }
  :deep(.el-checkbox__input.is-focus .el-checkbox__inner) {
    border: 1px solid rgba(12, 15, 74, 0.5);
    background: rgba(255, 255, 255, 0.15);
    transition: 0.2s ease-in-out;
  }
  :deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
    border: 4px solid #ffa500;
    background: transparent;
    transition: 0.2s ease-in-out;
  }
}

.list {
  height: 243px;
  overflow-y: auto;
  list-style: none;
  background-color: #fff;
  border-radius: 8px;
  padding: 13px 8px;
  margin: 12px 24px;
  box-shadow: 8px 12px 12px rgba(42, 42, 42, 0.1);

  li {
    color: #0c0f4a;
    text-align: left;
    border-bottom: 1px solid rgba(12, 15, 74, 0.2);
    padding: 14px 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    cursor: pointer;

    &:hover {
      background-color: rgba(255, 165, 0, 0.6);
      color: #fff;
      border-radius: 8px;
    }
  }
}
.list::-webkit-scrollbar {
  display: none;
}
</style>
